import { useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import { Box, Typography, CircularProgress, TextField, Button, Grid } from '@mui/material';
import Header from '../../atom/header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { useParams } from "react-router-dom";
import Image from '../../atom/image';
import Video from '../../atom/video';
import useWindowDimensions from '../../hooks';
import Alert from '@mui/material/Alert';


import './main.scss';



import { uploadFromBlobAsync, deleteImage, submitImages } from '../../storage';

interface IImage {
    url: string;
    type: string;
}

function App() {
    const [isLoading, setIsLoading] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState(null)
    const [message, setMessage] = useState<string | null>(null)
    const [images, setImages] = useState<Array<IImage>>([]);
    const [text, setText] = useState<string>('')
    const [type, setType] = useState<string>('')
    const [recordId, setRecordId] = useState<string>('')
    const { width } = useWindowDimensions();
    const [submitMessage, setSubmitMessage] = useState('');
    const [response, setResponse] = useState(0)
    const params = useParams();

    useEffect(() => {
        if (params.path) {
            setType(params.path);
        }
        if (params.id) {
            setRecordId(params.id);
        }
    }, [params])

    const deleteFile = async (file: string) => {
        const _images = [...images].filter(image => image.url !== file)
        await deleteImage(file);
        setImages(_images);
    }

    const submit = async () => {
        setSubmitting(true)
        const result = await submitImages({ images: images.map(image => image.url), type, recordId, text })

        setResponse(result.status)
        setSubmitMessage(result.data.message)
        setTimeout(() => {
            setSubmitting(false)
            setMessage('')
            setText('')
            setImages([])
        }, 1000)
    }
    const onDrop = async (acceptedFiles: any) => {
        setResponse(0)

        const file = acceptedFiles?.[0]

        if (!file) {
            return
        }


        setIsLoading(true)
        setError(null)
        setMessage(null)

        try {
            const response = await Promise.all(acceptedFiles.map((file: any) => {
                return uploadFromBlobAsync(
                    URL.createObjectURL(file),
                    `${file.name}_${Date.now()}`,
                    file.type
                )
            }));


            if (Array.isArray(response) && response.length > 0) {
                let _images = [...images];
                let _newImages = _images.concat(response)
                setImages(_newImages);
            }


        } catch (e: any) {
            setIsLoading(false)
            setError(e.message)
            return
        }

        setIsLoading(false)
        setMessage('File was uploaded 👍')
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: "image/*,video/*" })

    return (
        <Box className='main'>
            <Header />
            <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '600px', margin: 'auto', paddingTop: { xs: '20px', sm: '50px' }
            }}>
                <Typography style={{ fontWeight: '400', fontFamily: 'Poppins' }} className='title'>
                    Upload your images
                </Typography>
                <Box sx={{ marginTop: '20px', display: 'flex', flexDirection: 'column', padding: '15px 30px', background: '#fff', boxSizing: 'border-box', width: '100%' }} className='box'>
                    <Typography style={{ fontSize: '20px', fontFamily: 'poppins', borderBottom: 'solid 2px #dadada', paddingBottom: '10px' }}>
                        Upload here
                    </Typography>
                    <hr />
                    <Typography style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Poppins', textAlign: 'left', color: '#cccccc' }}>
                        Attach images
                    </Typography>
                    <Box sx={{
                        background: `${isDragActive ? '#eff9fe' : '#ffffff'}`,
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 1,
                        boxSizing: 'border-box',
                        border: 'dashed 3px #dadada',
                        textAlign: "center",
                        display: 'flex',
                        marginTop: '0px',
                        flexDirection: 'column',
                        color: '#cccccc',
                        padding: '20px',
                        minHeight: '128px',
                        cursor: 'pointer'
                    }}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} />
                        {message ? (
                            <FontAwesomeIcon icon={faCheckCircle} color='#05c8c6' size='3x' />
                        ) :
                            isLoading ? (
                                <CircularProgress sx={{ color: '#05c8c6' }} />
                            ) : isDragActive ? (
                                <Typography style={{ fontFamily: 'poppins' }}>Drop the files here...</Typography>
                            ) : (
                                <>
                                    <img src='/images/cloud-computing.png' style={{ width: '50px', margin: 'auto' }} alt='upload' />
                                    <Typography style={{ fontFamily: 'poppins' }}>Drag and drop  here</Typography>
                                    <Typography style={{ fontFamily: 'poppins' }}>or</Typography>
                                    <Typography style={{ fontFamily: 'poppins', color: '#05c8c6', fontWeight: '500' }}>Browse files</Typography>
                                </>
                            )}
                    </Box>
                    {
                        error && (
                            <Alert severity="error">{error}</Alert>
                        )
                    }
                    {
                        message && (
                            <Alert severity="success">{message}</Alert>
                        )
                    }

                    <Typography style={{ fontSize: '16px', fontWeight: '400', fontFamily: 'Poppins', textAlign: 'left', color: '#cccccc' }}>
                        Accepted File Types: Images/Video
                    </Typography>
                    <Box sx={{ marginTop: '20px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <Grid container spacing={2}>
                            {
                                images.map((image, index) => {
                                    return (
                                        <Grid item xs={12} sm={3} key={index} >
                                            {image.type.includes('video') ?
                                                (
                                                    <Video src={image.url} deleteFile={(file) => deleteFile(file)} width={width} />
                                                ) :
                                                (
                                                    <Image src={image.url} deleteFile={(file) => deleteFile(file)} width={width} />)}
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                    </Box>
                    {type === 'quote' &&
                        (<Box sx={{ marginTop: '10px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                            <Typography style={{ fontFamily: 'poppins', fontSize: '16px' }}>Please describe your images with as much detail as you can</Typography>
                            <TextField id="outlined-basic" value={text} variant="outlined" sx={{ background: '#fff', fontFamily: 'poppins', overflow: 'hidden' }} multiline rows={3} onChange={(event) => setText(event.target.value)} />
                        </Box>)}
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {!submitting ? (<Button disabled={images.length < 1} className='submit' sx={[{
                            '&:hover': {
                                backgroundColor: '#05c8c6',
                            },
                        }, { width: { xs: '100%', sm: '250px' }, opacity: `${images.length > 0 ? '1' : '0.3'}` }]} onClick={() => submit()}>Submit</Button>)
                            : (
                                <div className='loading-outer'>
                                    <CircularProgress size={20} color='inherit' />
                                </div>)}
                    </Box>
                    <Box>
                        {response === 200 && (
                            <Alert severity="success">{submitMessage}</Alert>
                        )}
                        {response === 400 && (
                            <Alert severity="error">{submitMessage}</Alert>
                        )}
                    </Box>
                </Box>
            </Box >
        </Box >
    )
}

export default App