import { Box, Typography, CircularProgress } from '@mui/material';
import './header.scss';

function Header() {

    return (
        <Box className='headerContainer'>
            <Box className='imgDiv'>
                <img src='/logo192.png' alt='Litta' />
            </Box>
        </Box >
    )
}

export default Header