// Libraries
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// Pages
import Main from '../components/Main';
import Error from '../components/Error';

// TODO Move to its own file.

export default function AppRouter() {

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Error />} />
                <Route path="/:path/:id" element={<Main />} />
                {/* Catch-All */}
                {/* <NoAuthLayout page={NotFound} /> */}
                <Route path="/" element={<Error />} />
            </Routes>
        </Router>
    );
}
