import { Box, Typography } from '@mui/material';
import Header from '../../atom/header';

function Error() {

    return (
        <Box sx={{ background: '#eeeeee' }} className='main'>
            <Header />
            <Box sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '600px', margin: 'auto', height: '100vh', paddingTop: '20vh'
            }}>
                <Typography sx={{ fontFamily: 'Poppins', fontSize: '30px', textAlign: 'center' }}>Please enter a valid record id</Typography>
            </Box >
        </Box>
    )
}

export default Error;