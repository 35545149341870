import { Box } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faFileVideo } from '@fortawesome/free-solid-svg-icons'


interface VideoProps {
    src: string;
    deleteFile: (src: string) => void;
    width: number;
}
function Video({ src, deleteFile, width }: VideoProps) {


    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
            <Box>
                <Box sx={{ border: 'solid 2px #dadada', borderRadius: '4px', background: '#fff', height: `${width > 599 ? '123px' : `${width - 110}px`}`, overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <FontAwesomeIcon icon={faFileVideo} color='#0000' style={{ fontSize: '80px', cursor: 'pointer', color: '#000' }} onClick={() => deleteFile(src)} />
                </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 5, right: 5, width: '32px', height: '32px', background: '#000', borderRadius: '50%', }}>
                <FontAwesomeIcon icon={faTimes} color='#ffffff' style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => deleteFile(src)} />
            </Box>
        </Box >
    )
}

export default Video