import storage from './firebase'
import axios from 'axios';
import {  ref, uploadBytes,getDownloadURL,StorageReference,deleteObject,getStorage } from "firebase/storage";

interface ImagesData{
  images: string[];
  recordId: string;
  type: string;
  text: string;
}
const upload = async(storageRef:StorageReference, blob:Blob)=>{
    const result = await uploadBytes(storageRef, blob)
    
    return result;
}

const getURL = async(ref:any)=>{
    const result = await  getDownloadURL(ref);

    return result

}
export const uploadFromBlobAsync = async (blobUrl:any, name:any, type?:any ) => {
  if (!blobUrl || !name) return null

  try {
    const storageRef = ref(storage, name);
    const blob = await fetch(blobUrl).then((r) => r.blob())
   
   const snapshot = await upload(storageRef, blob)
  
   const url = await getURL(snapshot.ref)
   
   return {
    url, type
  }
   

  } catch (error) {
    throw error
  }
}

export const deleteImage = async (url:string)=>{
  const storage = getStorage();

// Create a reference to the file to delete
const ImageRef = ref(storage, url);

// Delete the file
deleteObject(ImageRef).then(() => {
  console.log('deleted file')
}).catch((error) => {
  console.log(error)
  // Uh-oh, an error occurred!
});
}

export const submitImages = async (data: ImagesData)=>{
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': 'https://checkout.stripe.com/',

    'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
    'Access-Control-Allow-Headers': 'Content-Type, Authorization',
}

const result = await axios.post(
    'https://us-central1-imageuploader-dfb20.cloudfunctions.net/upload_images',
      data,
      {
          headers,
      }
    );

   return result
}